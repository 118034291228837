<template>
  <table class="table table-striped table-bordered mt-4">
    <thead>
      <tr>
        <th>Indicador</th>
        <th>Correlación</th>
        <th>Calidad</th>
        <th>Tendencia</th>
        <th>Pendiente</th>
        <th>Punto de partida</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ adelantado.nombre }}</td>
        <td>{{ coeficientes.correlacion | rounded }}</td>
        <td>{{ coeficientes.calidad | calidad }}</td>
        <td>{{ coeficientes.tendencia | tendencia }}</td>
        <td>{{ coeficientes.pendiente | rounded }}</td>
        <td>{{ coeficientes.intercepto | rounded }}</td>
        <td>
          <b-button size="sm" :to="enlaceSimulacion"> Simulación </b-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import { formatNewDateForm } from "@/services/date";
const PRECISION = 4;

function dround(n, p) {
  return Number(Math.round(n + "e" + p) + "e-" + p);
}

export default {
  props: ["rezagado", "adelantado", "coeficientes"],
  computed: {
    ...mapState("dashboard", ["cuadroMando", "filtros"]),
    enlaceSimulacion() {
      const cuadroMandoId = this.cuadroMando.id;
      const rezagadoId = this.rezagado.id;
      const adelantadoId = this.adelantado.id;
      const desde = formatNewDateForm(this.filtros.desde);
      const hasta = formatNewDateForm(this.filtros.hasta);
      const zona = this.filtros.zona;

      const encoded_params = new URLSearchParams({
        cuadroMandoId,
        rezagadoId,
        adelantadoId,
        zona,
        desde,
        hasta,
      }).toString();

      return `/simulaciones?${encoded_params}`;
    },
  },
  filters: {
    rounded(n) {
      if (isNaN(n)) {
        return "No se puede calcular";
      }

      return dround(n, PRECISION);
    },
    porcentual(n) {
      if (isNaN(n)) {
        return "No se puede calcular";
      }

      return dround(n, 2) + "%";
    },
    tendencia(n) {
      if (isNaN(n)) {
        return "No se puede calcular";
      }

      return n < 0 ? "Inversa" : "Directa";
    },
    calidad(n) {
      if (isNaN(n)) {
        return "No se puede calcular";
      }

      let a = Math.abs(n);
      if (a >= 0.75) {
        return "Buena";
      }
      if (a >= 0.45) {
        return "Media";
      }
      return "Mala";
    },
  },
};
</script>
